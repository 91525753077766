






















import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Getter, Mutation, Action, namespace } from 'vuex-class';
import Nav from '@/components/Nav.vue';
import AdminNav from '@/components/Admin/Nav.vue';

interface ArtistInfo {
  kor_name : string;
  eng_name : string;
  email: string;
  work_start_year: number;
}

@Component({
  components: {
    Nav,
    AdminNav
  }
})

export default class App extends Vue {
  @Action('fetchInfo', {namespace: 'Info'}) private fetchInfo:() => ArtistInfo;
  private isAdmin: boolean = null;

  private created() {
    if (this.$router.currentRoute.path.toString().includes('admin')) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    this.fetchInfo();
  }
}
