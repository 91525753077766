


























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';

interface MenuItem {
  name: string;
  route: string;
}

@Component({
})

export default class AdminNav extends Vue {
  private menuList: MenuItem[] = [
    {
      name: '전시',
      route: 'adminExhibition'
    },
    {
      name: '작업',
      route: 'adminWork'
    },
    {
      name: '텍스트',
      route: 'adminText'
    },
    {
      name: '기본정보',
      route: 'adminInfo'
    },
    {
      name: '약력',
      route: 'adminCV'
    },
    {
      name: '메인이미지',
      route: 'adminImage'
    }
  ];

}
