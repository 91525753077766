











































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
import axios from 'axios';

@Component({
})

export default class Nav extends Vue {
  private menuList: string[] = ['work', 'text', 'exhibition', 'cv', 'contact'];
  private isShowMenu: boolean = false;
  private aritstEngName: string = '';

  private created() {
    this.fetch();
  }

  private async fetch() {
    const { data } = await axios.get('/admin/api/info');
    this.aritstEngName = data.eng_name;
  }

  private switchMenu() {
    this.isShowMenu = !this.isShowMenu;
  }

  private closeMenu() {
    this.isShowMenu = false;
  }
}
