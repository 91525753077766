













































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Throttle, Debounce } from '../util/index';
import TWEEN from '@tweenjs/tween.js/src/Tween';

@Component({
  methods: {
    hideBar: Debounce(function(this: Scroll) {
      this.isActive = false;
    }, 500),
    drag: Throttle(function(this: Scroll, e: MouseEvent) {
      if (this.isDragging) {
        const scroll = this.$refs.scroll as HTMLElement;
        const delta = e.clientY - this.lastDragPos;
        scroll.scrollTop += (delta / this.ratio);

        this.lastDragPos = e.clientY;
      }
    }, 10)
  }
})
export default class Scroll extends Vue {
  @Prop(String) private tag;
  @Prop({default: '100%'}) private height;
  @Prop(Boolean) private isBottom;
  private ratio: number = 0;
  private isDragging: boolean = false;
  private isActive: boolean = false;
  private lastDragPos: number = 0;
  private savedPos: number = 0;
  private body: HTMLBodyElement = document.querySelector('body')!;
  private intersectionObserver: IntersectionObserver;

  public scrollTo(to: number) {
    function animate() {
      if (TWEEN.update()) {
        requestAnimationFrame(animate);
      }
    }

    const coords = { y: (this.$refs.scroll as HTMLElement).scrollTop };
    const tween = new TWEEN.Tween(coords)
      .to({ y: to }, 500)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        if (!!this.$refs.scroll) {
          (this.$refs.scroll as HTMLElement).scrollTop = coords.y;
        } else {
          this.$nextTick(() => {
            if (!!this.$refs.scroll) {
              (this.$refs.scroll as HTMLElement).scrollTop = coords.y;
            }
          });
        }
      })
      .start();

    animate();
  }

  private updated() {
    this.updateSize();
  }
  private mounted() {
    this.intersectionObserver = new IntersectionObserver((entries, obeserver) => {
      const isInit = !entries.every((entry) => {
        return !(entry.target.classList.contains('scroll') && entry.isIntersecting);
      });

      if (isInit) {
        this.scrollTo(this.savedPos);
        this.updateSize();
      } else {
        entries.forEach((entry) => {
          if (entry.target !== this.$refs.scroll) {
            const { type } = (entry.target as HTMLElement).dataset;

            if (entry.isIntersecting) {
              this.$emit(type!);
            }

            if (type === 'bottom') {
              this.$emit('update:isBottom', entry.isIntersecting);
            }
          }
        });
      }
    }, {
      root: this.$el
    });
    this.intersectionObserver.observe(this.$refs.scroll as HTMLElement);
    this.intersectionObserver.observe(this.$refs.top as HTMLElement);
    this.intersectionObserver.observe(this.$refs.bottom as HTMLElement);

    /* TODO: 가끔 문제있는 것 같아서 쓰로틀링 일단 뺌 */
    window.addEventListener('resize', this.updateSize);
  }

  private destroyed() {
    window.removeEventListener('resize', this.updateSize);
    // @ts-ignore
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('mouseup', this.dragEnd);
  }


  /* TODO: 가끔 문제있는 것 같아서 쓰로틀링 일단 뺌 */
  private scroll() {
    const scroll = this.$refs.scroll as HTMLElement;
    const bar = this.$refs.bar as HTMLElement;

    this.isActive = true;
    // @ts-ignore
    this.hideBar();

    this.savedPos = scroll.scrollTop;
    bar.style.top = `${scroll.scrollTop * this.ratio}px`;
  }

  private updateSize() {
    const scroll = this.$refs.scroll as HTMLElement;
    const bar = this.$refs.bar as HTMLElement;

    if (scroll && bar) {
      this.ratio = scroll.clientHeight / scroll.scrollHeight;
      bar.style.height = `${scroll.clientHeight * this.ratio}px`;
      bar.style.top = `${scroll.scrollTop * this.ratio}px`;
    }
  }

  private dragStart(e: MouseEvent) {
    this.isDragging = true;
    this.lastDragPos = e.clientY;
    this.body.classList.add('scrolling');

    // @ts-ignore 위의 데코레이터에서 정의해서 타입을 못찾음.
    document.addEventListener('mousemove', this.drag);
    document.addEventListener('mouseup', this.dragEnd);
  }

  private dragEnd() {
    this.isDragging = false;
    this.body.classList.remove('scrolling');

    // @ts-ignore
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('mouseup', this.dragEnd);
  }
}
